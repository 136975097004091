import { ErrorHandler, ERROR_CATCH_TYPE } from "@classdojo/web/pods/errorHandling";
import { UserActionsHistory } from "@classdojo/web/pods/userActionsHistory";
import { reset as resetSagaCounter } from "@web-monorepo/infra/createWaitableSaga";
import { flatten } from "@web-monorepo/shared/podInfra";
import { createStore, combineReducers, applyMiddleware } from "redux";
import type { Middleware, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import allPods from "app/pods/allPods";
import env from "app/utils/env";

let _store: Store;

export default function createHomeStore(errorHandler: ErrorHandler, userActionsHistory: UserActionsHistory) {
  const {
    reducerMap,
    sagas,
    middleware: podMiddleware,
  } = flatten(
    // @ts-expect-error Argument of type '(PodInstallFunction | PodInstallFunction)[]' is not assignable to parameter of type 'PodInstallFunction[]'.
    allPods,
  );
  const combinedReducers = combineReducers({
    ...reducerMap,
  });

  const sagaMiddleware = createSagaMiddleware();

  let isDead = false;
  const teardownMiddleware: Middleware = () => (next) => (action) => {
    if (!isDead) {
      return next(action);
    }
  };

  const middleware = [
    teardownMiddleware,
    errorHandler.reduxMiddleware,
    sagaMiddleware,
    userActionsHistory.reduxMiddleware,
    ...podMiddleware,
  ];

  _store = createStore(combinedReducers, applyMiddleware(...middleware));

  global.store = _store;

  const sagaTasks = sagas.map((saga) => {
    const task = sagaMiddleware.run(saga);
    task.toPromise().catch((e: any) => {
      try {
        errorHandler.handleError(e, { catchType: ERROR_CATCH_TYPE.SAGA_TASK });
      } catch (err) {
        // in testing, throwing again will trigger the unhandledRejection handler and fail
        // the test suite.
        // when not testing, we want to rethrow to ensure the error surfaces to the console
        // for better dev experience.
        if (!env.isTesting) {
          throw err;
        }
      }
    });
    return task;
  });

  return {
    store: _store,
    teardown: () => {
      isDead = true;

      // stop all currently running sagas
      sagaTasks.forEach((task) => task.cancel());
      resetSagaCounter();
    },
  };
}

export function getStore() {
  return _store;
}
