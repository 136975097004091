import { useSideEffectOnMount } from "@web-monorepo/hooks";
import "extended-proptypes/lib/extend-from-standalone";
// throw error for failling PropTypes checks
import "@web-monorepo/shared/consoleOverrides/throwPropTypesErrors";
import "@web-monorepo/shared/consoleOverrides/ignoreReactLifeCycleWarnings";
import "app/initialize";

import { NessieThemeProvider } from "@classdojo/web/nessie";
import { ErrorHandlerContext } from "@classdojo/web/pods/errorHandling";
import ErrorBanner from "@classdojo/web/pods/errorHandling/components/ErrorBanner";
import { ProductAreaContextProvider } from "@classdojo/web/pods/errorHandling/productArea";
import { UserActionsHistory } from "@classdojo/web/pods/userActionsHistory";
import { ReactQueryProvider } from "@web-monorepo/shared/reactQuery/provider";
import {
  scheduleRefresh,
  addMetaTagToRefreshNearMidnight,
  refreshIfOldVersion,
} from "@web-monorepo/shared/utils/midnightRefresh";
import { DesignSystemVariables, DDSScreenReaderAnnouncer, DDSProvider } from "@web-monorepo/dds";
import * as Telemetry from "@web-monorepo/telemetry";
import { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { createWebErrorHandler } from "app/errorHandling";
import { DevTools } from "app/pods/devTools";
import { setAppFeatureSwitchesConfig } from "app/pods/featureSwitches/config";

import createStore from "app/store";
import FixBadPaths from "@web-monorepo/shared/router/FixBadPaths";
import { RouterNavigateProvider } from "@web-monorepo/shared/router/reactRouterNavigate";

import ApplicationContainer from "../../app/views/containers/ApplicationContainer";
import { Outlet } from "react-router-dom";
import { MatomoConfiguredProvider } from "../../app/utils/MatomoConfiguredProvider";
import { ModalsContextProvider } from "@classdojo/web/pods/automatedEvents/modalsContext";
import AppTopLevelErrorBoundary from "@classdojo/web/pods/errorHandling/components/AppTopLevelErrorBoundary";
import { LogClient } from "@classdojo/log-client";
import { ThemesSection } from "@web-monorepo/shared/devTools/ThemesSection";
import { DevToolsLogoutButton } from "@web-monorepo/shared/devTools/DevToolsLogoutButton";
import { ColorThemeContext, useColorTheme } from "@classdojo/web/nessie/components/useColorTheme";
import { OTCPageProvider } from "@web-monorepo/one-time-codes";
import { translate } from "app/utils/translate";

import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  useSearchParams,
} from "react-router-dom";
import { useLogoutOperation } from "app/pods/session";
import { useParams } from "app/generatedRoutes";
import useWatch from "@classdojo/web/hooks/useWatch";

setAppFeatureSwitchesConfig();
scheduleRefresh("home");
refreshIfOldVersion({ buildAgeInDays: 3, releaseAgeInDays: 1 });

//
// Initialize Datadog RUM
// https://www.datadoghq.com/dg/real-user-monitoring/overview/
//
// if (Config.nodeEnv === "production") {
//   initializeDatadogRUMForHome();
// }

const userActionsHistory = new UserActionsHistory();
//
// Register browser history changes in our 'user actions module'
// We are calling this code 'early' and only once. To ensure we track any navigation or url change that happens
// even before we mount the top component in the app.
userActionsHistory.listenToBrowserUrlChanges();

declare global {
  interface Window {
    appVersion: string;
    build: string;
    logClient?: LogClient;
  }
}

if (Config.nodeEnv === "production") {
  Telemetry.init({
    dsn: "https://5c51355865402090ed03d41319214223@sentry.classdojo.com/6",
    tracing: {
      reactRouter: {
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      },
    },
    replays: {
      sessionSampleRate: 0.1,
      onErrorSampleRate: 1,
    },
  });
}

/* export function Catch() {
  return <h1>A proglem happened</h1>;
} */

export default function App() {
  const [errorHandler] = useState(() => createWebErrorHandler(userActionsHistory));

  const [{ store }] = useState(() =>
    // Build the redux store with all the reducers configured + middlewares.
    createStore(errorHandler, userActionsHistory),
  );

  useSideEffectOnMount(() => {
    addMetaTagToRefreshNearMidnight();
  });
  Telemetry.useUrlTags({
    useLocation,
    useSearchParams,
    // @ts-expect-error Type '<P extends never>(path: P) => Params[P]' is not assignable to type '<P extends never>(path?: P | undefined) => string | undefined'.
    useUrlParams: useParams,
    useWatch,
    setTag: Telemetry.setTag,
  });

  const { selectedTheme, mode, setNewTheme } = useColorTheme();

  return (
    <Provider store={store}>
      <DesignSystemVariables theme="light" />
      <DDSScreenReaderAnnouncer />
      <FixBadPaths />
      <RouterNavigateProvider />
      <ReactQueryProvider>
        <ErrorHandlerContext.Provider value={errorHandler}>
          <ProductAreaContextProvider>
            <DDSProvider>
              <NessieThemeProvider selectedTheme={selectedTheme}>
                {/*With new i18n, translations are loaded if we are displaying the chrome*/}
                <ErrorBanner useIsLocaleLoaded={() => true} />
                <AppTopLevelErrorBoundary errorHandler={errorHandler}>
                  <MatomoConfiguredProvider>
                    <ModalsContextProvider>
                      <ApplicationContainer>
                        <OTCPageProvider entityType="parent" translate={translate}>
                          <Outlet />
                        </OTCPageProvider>
                      </ApplicationContainer>
                    </ModalsContextProvider>
                  </MatomoConfiguredProvider>
                  <ColorThemeContext.Provider value={{ setNewTheme, mode }}>
                    <DevTools
                      additionalSections={{
                        Themes: { title: "Themes", component: ThemesSection },
                        Logout: { title: "Logout", component: DevToolsLogoutButtonWithAction },
                      }}
                    />
                  </ColorThemeContext.Provider>
                </AppTopLevelErrorBoundary>
              </NessieThemeProvider>
            </DDSProvider>
          </ProductAreaContextProvider>
        </ErrorHandlerContext.Provider>
      </ReactQueryProvider>
    </Provider>
  );
}

const DevToolsLogoutButtonWithAction = () => {
  const { mutate: logOut } = useLogoutOperation();
  const logOutAction = () => logOut(undefined);
  return <DevToolsLogoutButton logoutAction={logOutAction} />;
};
