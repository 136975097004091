import { makeCollectionQuery, makeMemberQuery } from "../reactQuery";

/* @deprecated */
export const useAllClassroomFetcher = makeCollectionQuery({
  fetcherName: "allClassroom",
  path: "/api/dojoClass",
  query: {
    withPending: "true",
    withArchived: "true",
    withRequestedCollaborators: "true",
    skipUnreadNotificationCounts: "true",
  },
});

/**
 * This endpoint will return ONLY personal classes
 * it will not return any classes that are pending in a school or archived
 * it will not return any classes that are not associated with the current teacher
 * it will not return any pending shared classes
 */

export const usePersonalClassesFetcher = makeCollectionQuery({
  fetcherName: "personalClassesNG",
  path: "/api/personal-classes",
});

/**
 * This endpoint will return all classes that are for me in a school
 */
export const useMyClassesFetcher = makeCollectionQuery({
  fetcherName: "myClasses",
  path: "/api/dojoSchool/{schoolId}/my-classes",
});

export const useArchivedClassesFetcher = makeCollectionQuery({
  fetcherName: "archivedClasses",
  path: "/api/archived-classes",
});

export const useArchivedClassesCountFetcher = makeMemberQuery({
  fetcherName: "archivedClassesCount",
  path: "/api/archived-classes/count",
});

export const useDemoClassesFetcher = makeCollectionQuery({
  fetcherName: "demoClasses",
  path: "/api/demo-classes",
});

export const usePersonalClassInvitesFetcher = makeCollectionQuery({
  fetcherName: "personalClassInvites",
  path: "/api/personal-class-invites",
});

export const useSchoolClassInvitesFetcher = makeCollectionQuery({
  fetcherName: "schoolClassInvites",
  path: "/api/dojoSchool/{schoolId}/class-invites",
});

/* @deprecated */
export const useSchoolClassesFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{schoolId}/class/v2",
  query: { withoutInactive: "false", withRequestedCollaborators: "true" },
  fetcherName: "schoolClasses",
});

export const useSchoolAllClassesFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{schoolId}/all-classes",
  fetcherName: "schoolAllClasses",
});

export const useSchoolAllClassesCountFetcher = makeMemberQuery({
  path: "/api/dojoSchool/{schoolId}/all-classes/count",
  fetcherName: "schoolAllClassesCount",
});

export const useSchoolArchivedClassesFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{schoolId}/archived-classes",
  fetcherName: "schoolArchivedClasses",
});

export const useSchoolArchivedClassesCountFetcher = makeMemberQuery({
  path: "/api/dojoSchool/{schoolId}/archived-classes/count",
  fetcherName: "schoolArchivedClassesCount",
});

export const useSchoolOtherClassesFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{schoolId}/other-classes",
  fetcherName: "schoolOtherClasses",
});

export const useSchoolOtherClassesCountFetcher = makeMemberQuery({
  path: "/api/dojoSchool/{schoolId}/other-classes/count",
  fetcherName: "schoolOtherClassesCount",
});

export function invalidateClassQueries() {
  useAllClassroomFetcher.invalidateQueries();
  usePersonalClassesFetcher.invalidateQueries();
  useMyClassesFetcher.invalidateQueries();
  useArchivedClassesFetcher.invalidateQueries();
  useArchivedClassesCountFetcher.invalidateQueries();
  useDemoClassesFetcher.invalidateQueries();
  useSchoolClassesFetcher.invalidateQueries();
  useSchoolAllClassesFetcher.invalidateQueries();
}
