import callApi from "@web-monorepo/infra/callApi";
import {
  APIResponse,
  CollectionFetcherReturnType,
  MemberFetcherReturnType,
} from "@web-monorepo/shared/api/apiTypesHelper";
import { DojoError } from "@web-monorepo/shared/errors/errorTypeMaker";
import { makeApiMutation, makeCollectionQuery, makeMemberQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import pick from "lodash/pick";
import errors from "app/errors";
import { useSessionFetcher } from "app/pods/session";
import matchesError from "app/utils/matchesError";
import { useUserConfigFetcher } from "app/pods/userConfig";
import { isApiResponseError } from "@web-monorepo/infra/responseHandlers";
import { useStudentsFetcher } from "app/pods/student";

//
// -----------------------------------------
// FETCHERS
//
export const useFetchedParent = (which: "standard" | "any"): Parent => {
  const { data } = useSessionFetcher({});

  const parent = data && data.parent;

  if (which === "standard" && data?.isChildAsParent) {
    // @ts-expect-error We have a lot of callers that expect a parent.
    return undefined;
  }

  // @ts-expect-error We have a lot of callers that expect a parent.
  return parent;
};

export const useParentProfilePictureUrl = () => {
  const parent = useFetchedParent("any");
  return parent ? parent.avatarURL : undefined;
};

type UpdateParentResponse = APIResponse<"/api/parent/{id}", "put">;

type UpdateParentParams = {
  parent: Parent;
  propsToUpdate: Record<string, unknown>;
};

function getUpdateProfilePayload(parent: Parent, propsToUpdate: Record<string, unknown>) {
  const fields = [
    "_id",
    "locale",
    "firstName",
    "lastName",
    "emailAddress",
    "password",
    "avatarURL",
    "marketingEmailOptOut",
  ];
  return pick({ ...parent, ...propsToUpdate }, fields);
}

export const useUpdateParentOperation = makeMutation<UpdateParentParams, { body: UpdateParentResponse }, DojoError>({
  name: "updateParent",
  fn: async ({ parent, propsToUpdate }) => {
    try {
      return await callApi({
        method: "PUT",
        path: `/api/parent/${parent._id}`,
        body: getUpdateProfilePayload(parent, propsToUpdate),
      });
    } catch (err: any) {
      if (matchesError(err.response, "Email already exists")) {
        return errors.parent.emailAlreadyExist();
      }
      throw err;
    }
  },
  onSuccess: () => {
    useSessionFetcher.invalidateQueries();
    useUserConfigFetcher.invalidateQueries();
  },
});

export const useAddKidOperation = makeApiMutation({
  name: "addKid",
  path: "/api/parent/{parentId}/student",
  method: "post",

  onSuccess: () => {
    useStudentsFetcher.invalidateQueries();
  },
});

type UseUpdateProfilePictureParams = {
  parent: Parent;
  url?: string;
};
type UseUpdateProfilePictureResponse = APIResponse<"/api/parent/{id}", "put">;

export const useUpdateProfilePictureOperation = makeMutation<
  UseUpdateProfilePictureParams,
  { body: UseUpdateProfilePictureResponse }
>({
  name: "updateProfilePicture",
  fn: async ({ parent, url }) => {
    return await callApi({
      method: "PUT",
      path: `/api/parent/${parent._id}`,
      body: getUpdateProfilePayload(parent, { avatarURL: url }),
    });
  },
  onMutate: (params) => {
    useSessionFetcher.setQueriesData((draft) => {
      if (!draft.parent) return draft;

      draft.parent.avatarURL = params.url;
    });
  },
});

export type EmailValidationCode = MemberFetcherReturnType<typeof useEmailValidationFetcher>;

export const useEmailValidationFetcher = makeMemberQuery({
  fetcherName: "emailValidation",
  path: "/api/user/emailValidation/{email}",
});

export const useInvitationCodeFetcher = makeMemberQuery({
  fetcherName: "parentInvitationCode",
  path: "/api/invitation/{identifier}",
  dontThrowOnStatusCodes: [400, 404],
});
export type InvitationCode = MemberFetcherReturnType<typeof useInvitationCodeFetcher>;

export const useCommunityGroupDetailsFromInviteCodeFetcher = makeMemberQuery({
  fetcherName: "communityGroupDetailsFromInviteCode",
  path: "/api/communities/invite-code/{inviteCode}",
  dontThrowOnStatusCodes: [400, 404],
});
export type CommunityGroupDetailsFromInviteCode = MemberFetcherReturnType<
  typeof useCommunityGroupDetailsFromInviteCodeFetcher
>;

export const useRedeemInviteCodeOperation = makeApiMutation({
  name: "redeemInviteCode",
  path: "/api/communities/redeem-invite-code",
  method: "post",
  catchError: (error) => {
    if (error.message === "not found") {
      return new Error("Invitation not found");
    }
    return error;
  },
});

export const useParentReconnectingDataFetcher = makeCollectionQuery({
  fetcherName: "parentReconnectingData",
  path: "/api/parentReconnecting",
});

export type ParentReconnecting = CollectionFetcherReturnType<typeof useParentReconnectingDataFetcher>;

export const useDismissParentReconnectingClassOperation = makeMutation({
  name: "dismissParentReconnecting",
  fn: async ({ classId }: { classId: string }) => {
    return await callApi({
      method: "DELETE",
      path: `/api/parentReconnecting/${classId}`,
    });
  },
  onSuccess: () => {
    useParentReconnectingDataFetcher.invalidateQueries();
  },
});

export const useSendEmailVerification = makeApiMutation({
  name: "sendVerificationEmail",
  path: "/api/sendEmailVerification",
  method: "post",
});

export const useDeleteParentAccount = makeApiMutation({
  name: "deleteParentAccount",
  path: "/api/parent/me",
  method: "delete",
  onSuccess: () => {
    useSessionFetcher.invalidateQueries();
  },
  useErrorBoundary: (error) => {
    if (isApiResponseError(error) && [401, 409].includes(error.response.status)) {
      return false;
    }
    return true;
  },
});
export const usePrivacyPolicyUpdateAcceptedOperation = makeApiMutation({
  name: "updatePrivacyPolicyConsent",
  path: "/api/privacyPolicyUpdateAccepted",
  method: "post",
  onSuccess: () => {
    usePrivacyPolicyUpdateAcceptedFetcher.invalidateQueries();
  },
});

export const usePrivacyPolicyUpdateAcceptedFetcher = makeMemberQuery({
  fetcherName: "privacyPolicyUpdateAccepted",
  path: "/api/privacyPolicyUpdateAccepted",
});

export type SessionResponse = MemberFetcherReturnType<typeof useSessionFetcher>;
export type ParentSession = NonNullable<SessionResponse["parent"]>;
export type Parent = NonNullable<MemberFetcherReturnType<typeof useSessionFetcher>["parent"]>;
