import callApi from "@web-monorepo/infra/callApi";
import { APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { DojoError } from "@web-monorepo/shared/errors/errorTypeMaker";
import { makeMemberQuery, makeMutation } from "@web-monorepo/shared/reactQuery";

//
// -------------------------------
// Setup
//
export default function install() {}

type UseSaveMetadataParams = {
  key: string;
  value: unknown;
};
type UseSaveMetadataResponse = APIResponse<"/api/userConfig/metadata/{metaDataName}", "put">;

export const useSaveMetadataOperation = makeMutation<
  UseSaveMetadataParams,
  { body: UseSaveMetadataResponse },
  DojoError
>({
  name: "saveMetadata",
  fn: async ({ key, value }) =>
    callApi({
      method: "PUT",
      path: `/api/userConfig/metadata/${key}`,
      body: {
        value,
      },
    }),
  onMutate: (params) => {
    useUserConfigFetcher.setQueriesData((cacheData) => {
      if (!cacheData.metaData) {
        cacheData.metaData = {};
      }
      cacheData.metaData[params.key] = params.value;
    });
  },
  onSuccess: (data, params) => {
    useUserConfigFetcher.setQueriesData((cacheData) => {
      if (!cacheData.metaData) {
        cacheData.metaData = {};
      }
      cacheData.metaData[params.key] = data.body.value;
    });
  },
});

export const useUserConfigFetcher = makeMemberQuery({
  fetcherName: "userConfig",
  path: "/api/userConfig",
  query: {
    featureFlags: "false",
    experiments: "false",
  },
});

export const useFetchedUserConfigMetadata = <T>(key: string): T | undefined => {
  const { data } = useUserConfigFetcher({});
  return data?.metaData?.[key] as T | undefined;
};

export const useCurrentCountryFetcher = makeMemberQuery({
  path: "/api/country",
  fetcherName: "country",
  dontThrowOnStatusCodes: [404],
});

const useMonsterverseConfigFetcher = makeMemberQuery({
  path: `/api/monsterverseConfig`,
  fetcherName: "monsterverseConfig",
});

export const useMonsterverseAllowParentToOptOutHomeIslands = () => {
  const { data: monsterverseConfig } = useMonsterverseConfigFetcher({});
  if (monsterverseConfig?.type === "parent") {
    return !!monsterverseConfig.allowParentToOptOutHomeIslands;
  }
  return false;
};

export const useMonsterverseAllowParentToSetPermissionsV3 = () => {
  const { data: monsterverseConfig } = useMonsterverseConfigFetcher({});
  if (monsterverseConfig?.type === "parent") {
    return !!monsterverseConfig.allowParentToSetPermissionsV3_1;
  }
  return false;
};

export const useMonsterverseHomeIslandsAccessFetcher = makeMemberQuery({
  path: `/api/parent/{parentId}/student/{studentId}/homeIslandsAccess`,
  fetcherName: "monsterverseHomeIslandsAccess",
});
