/* eslint-disable react-refresh/only-export-components */

import { Fragment, Suspense } from "react";
import { Outlet } from "react-router-dom";
import type { ActionFunction, RouteObject, LoaderFunction } from "react-router-dom";
import { generatePreservedRoutes, generateRegularRoutes } from "@generouted/react-router/core";
import { ReactRouterErrorBoundary } from "@classdojo/web";
import { ModuleError } from "./ModuleError";
import Unhandled404 from "./404";
import { captureException } from "@web-monorepo/telemetry";

type Element = () => JSX.Element;
type Module = {
  default: Element;
  Loader?: LoaderFunction;
  Action?: ActionFunction;
  Catch?: Element;
  Pending?: Element;
};

const PRESERVED = import.meta.glob<Module>("/src/pages/(_app|404).tsx", { eager: true });
const ROUTES = import.meta.glob<Module>([
  "/src/pages/**/[\\w[-]*.{jsx,tsx}",
  "!/**/(_app|404).*",
  "!/**/_components",
  "!/**/*.stories.*",
]);

const preservedRoutes = generatePreservedRoutes<Omit<Module, "Action">>(PRESERVED);

const regularRoutes = generateRegularRoutes<RouteObject, () => Promise<Partial<Module>>>(ROUTES, (module, key) => {
  const index = /index\.(jsx|tsx)$/.test(key) && !key.includes("pages/index") ? { index: true } : {};

  return {
    ...index,
    lazy: async () => {
      try {
        const realizedModule = await module();

        const Element = realizedModule?.default || Fragment;
        const Pending = realizedModule?.Pending;
        const Page = () =>
          Pending ? (
            <Suspense fallback={<Pending />}>
              <Element />
            </Suspense>
          ) : (
            <Element />
          );

        return {
          Component: Page,
          ErrorBoundary: realizedModule?.Catch,
          loader: realizedModule?.Loader,
          action: realizedModule?.Action,
        };
        // eslint-disable-next-line no-catch-all/no-catch-all -- we do want to catch all and render an error page.
      } catch (x) {
        captureException(x);

        return {
          Component: () => <ModuleError error={x} />,
        };
      }
    },
  };
});

const _app = preservedRoutes?._app;
// This pattern should come into play once we are done 404s falling through to LazyManualRoutes:
// const _404 = preservedRoutes?.["404"];

const Element = _app?.default || Fragment;
const App = () =>
  _app?.Pending ? (
    <Suspense fallback={<_app.Pending />}>
      <Element />
    </Suspense>
  ) : (
    <Element />
  );

const app = { Component: _app?.default ? App : Outlet, ErrorBoundary: _app?.Catch, loader: _app?.Loader };

export const routes: RouteObject[] = [
  {
    ...app,
    errorElement: <ReactRouterErrorBoundary />,
    children: [
      ...regularRoutes,
      {
        path: "*",
        element: <Unhandled404 />,
      },
    ],
  },
];
