import * as logClient from "@classdojo/log-client";
import useWatch from "@classdojo/web/hooks/useWatch";
import callApi from "@web-monorepo/infra/callApi";
import { isApiResponseError } from "@web-monorepo/infra/responseHandlers";
import { UserCancelledCodeEntryError, withOTCHandling } from "@web-monorepo/one-time-codes";
import { APIResponse, MemberFetcherReturnType } from "@web-monorepo/shared/api/apiTypesHelper";
import { DojoError } from "@web-monorepo/shared/errors/errorTypeMaker";
import { makeApiMutation, makeMemberQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import errors from "app/errors";
import { FEATURE_SWITCHES } from "app/pods/featureSwitches/constants";
import { logParentSignup } from "app/pods/signup";
import * as location from "app/utils/location";
import matchesError from "app/utils/matchesError";
import { useCallback, useState } from "react";

type UseLogoutParams = { redirectUrl?: string } | void;
type UseLogoutResponse = APIResponse<"/api/session", "delete"> | void;
export const useLogoutOperation = makeMutation<UseLogoutParams, UseLogoutResponse>({
  name: "logout",
  fn: async ({ redirectUrl } = {}) => {
    try {
      await callApi({
        method: "DELETE",
        path: "/api/session",
      });
    } catch (err: any) {
      // treat 401 as expected error and ignore it
      if (!err.response || err.response?.status !== 401) {
        throw err;
      }
    }

    location.navigateTo(redirectUrl || "/");
  },
  onSuccess: () => {
    useSessionFetcher.invalidateQueries();
  },
});

type UseResetPasswordParams = {
  email?: string;
};
type UseResetPasswordResponse = APIResponse<"/api/passwordReset", "post">;

export const useResetPasswordOperation = makeMutation<
  UseResetPasswordParams,
  { body: UseResetPasswordResponse },
  DojoError
>({
  name: "resetPassword",
  fn: async ({ email }) => {
    try {
      return await callApi({
        method: "POST",
        path: "/api/passwordReset",
        body: { emailAddress: email },
      });
    } catch (error: any) {
      const errorMessage = error?.response?.body?.error?.detail?.message || error?.response?.body?.error?.detail;
      const isString = typeof errorMessage === "string";
      if (isString && /user is suspended/i.test(errorMessage)) {
        return errors.reset.suspended();
      } else if (error.response?.status === 400) {
        return errors.reset.expired();
      } else if (isString && /no parent confirmed/.test(errorMessage)) {
        return errors.reset.cantReset();
      } else {
        throw error;
      }
    }
  },
});

type UseSetNewPasswordParams = {
  resetId?: string;
  newPassword: string;
  hash: string | null;
};
type UseSetNewPasswordResponse = APIResponse<"/api/passwordReset/{id}", "post">;

export const useSetNewPasswordOperation = makeMutation<
  UseSetNewPasswordParams,
  { body: UseSetNewPasswordResponse },
  DojoError
>({
  name: "setNewPassword",

  fn: async ({ resetId, newPassword, hash }) => {
    try {
      return await callApi({
        method: "POST",
        path: `/api/passwordReset/${resetId}`,
        body: { password: newPassword },
        query: { h: hash || "" },
      });
    } catch (error: any) {
      if (error.response?.status === 404) {
        return errors.reset.email();
      } else if (error.response?.status === 400) {
        return error;
      }
      throw error;
    }
  },
});

type UseLoginError = DojoError & {
  remainingAttempts?: number;
  response?: {
    body: {
      error: {
        code: string;
        detail: {
          firstName: string;
          lastName: string;
          emailAddress: string;
          loginHint?: string;
        };
      };
    };
  };
};

export type LoginParams = {
  email?: string;
  password?: string;
  signup?: boolean;
  code?: string;
  duration?: "short" | "long";
  // for google login, we use code and state.
  state?: string;
  autoCreate?: boolean;
};

export const useSendLoginCodeOperation = makeMutation<{ email: string }, void>({
  name: "loginCodeOperation",
  fn: async ({ email }) => {
    try {
      await callApi({
        method: "POST",
        path: "/api/oneTimeCode",
        body: { email },
      });
    } catch (err: any) {
      if ([400, 404, 429].includes(err.response?.status)) {
        return err;
      }

      throw err;
    }
  },
});

function _logSignInSuccess({
  entityId,
  accountType,
  signup,
  loginType,
  duration,
}: {
  entityId: string;
  accountType: "parent" | "student" | "teacher";
  signup?: boolean;
  duration?: string;
  loginType: "email" | "login_code" | "google";
}) {
  // do not log web.login.sucess in case of a new account has been created
  // for those cases we want only web.signup.succes to be logged
  if (signup) {
    return;
  }
  logClient.logConvertExperiment(FEATURE_SWITCHES.WEB_EXTERNAL_LOGIN_TO_HOME);

  if (loginType == "google") {
    logClient.logEvent({
      eventName: "web.login.success.google",
      experiments: [FEATURE_SWITCHES.WEB_EXTERNAL_LOGIN_TO_HOME],
      metadata: {
        current_site: "parent",
        ref: "unknown",
        account_type: accountType,
        login_type: loginType,
        duration,
      },
      entityId,
    });
  }

  logClient.logEvent({
    eventName: "web.login.success",
    experiments: [FEATURE_SWITCHES.WEB_EXTERNAL_LOGIN_TO_HOME],
    metadata: {
      current_site: "parent",
      ref: "unknown",
      account_type: accountType,
      login_type: loginType,
      duration,
    },
    entityId,
  });
}

type SuccessLogParams = Parameters<typeof _logSignInSuccess>[0];

const logSignInSuccess = ({
  entityId,
  accountType,
  loginType,
  signup,
  duration,
}: {
  entityId: string;
  accountType: "parent" | "student" | "teacher";
} & Pick<SuccessLogParams, "loginType" | "signup" | "duration">) =>
  _logSignInSuccess({
    entityId,
    accountType,
    signup,
    loginType,
    duration,
  });

const getErrorMetadata = (error: Error) => {
  if (error instanceof UserCancelledCodeEntryError) {
    return {
      remainingAttempts: null,
      failureReason: "user_cancelled_otp_entry",
      wrongPassword: false,
      wrongUsername: false,
      wrongCode: false,
      suspendedUser: false,
      forceResetPassword: false,
      mustUseSso: false,
      isGoogleUserNotFound: false,
      userCancelledOTPEntry: true,
    };
  }

  if (!isApiResponseError(error)) {
    return {
      remainingAttempts: null,
      failureReason: null,
      wrongPassword: null,
      wrongUsername: null,
      wrongCode: null,
      suspendedUser: null,
      forceResetPassword: null,
      mustUseSso: null,
      userCancelledOTPEntry: null,
      isGoogleUserNotFound: null,
    };
  }

  const remainingAttempts = error?.response?.header?.["remaining-attempts"];
  const wrongPassword = matchesError(error.response, "Incorrect password");
  const wrongUsername = matchesError(error.response, "Incorrect username");
  const wrongCode = matchesError(error.response, "Incorrect code");
  const suspendedUser = matchesError(error.response, "User is suspended");
  const forceResetPassword = error?.response?.body?.error?.code === "ERR_COMPROMISED_PASSWORD";
  const mustUseSso = matchesError(error.response, "Must use SSO");
  const isGoogleUserNotFound = error?.response?.body?.error?.code === "ERR_GOOGLE_USER_NOT_FOUND";
  const mustUseOtc = error?.response?.body?.error?.code?.startsWith("ERR_MUST_USE_OTC");

  const failureReason = wrongPassword
    ? "wrong_password"
    : wrongUsername
      ? "wrong_username"
      : suspendedUser
        ? "suspended"
        : wrongCode
          ? "forceResetPassword"
          : forceResetPassword
            ? "invalid_code"
            : mustUseSso
              ? "must_use_sso"
              : mustUseOtc
                ? "must_use_otc"
                : "";

  return {
    remainingAttempts,
    failureReason,
    wrongPassword,
    wrongUsername,
    wrongCode,
    suspendedUser,
    forceResetPassword,
    mustUseSso,
    mustUseOtc,
    isGoogleUserNotFound,
    userCancelledOTPEntry: false,
  };
};

const mapUnknownLoginErrorToDojoError = (error: unknown): DojoError | undefined => {
  if (!(error instanceof Error)) {
    return undefined;
  }

  const {
    wrongCode,
    suspendedUser,
    forceResetPassword,
    wrongPassword,
    wrongUsername,
    remainingAttempts,
    mustUseOtc,
    userCancelledOTPEntry,
    isGoogleUserNotFound,
  } = getErrorMetadata(error);

  if (isGoogleUserNotFound) {
    return error as DojoError;
  }

  if (userCancelledOTPEntry) {
    return errors.login.userCancelledOTPEntry();
  }

  if (suspendedUser) {
    return errors.login.suspended();
  }

  if (wrongPassword || wrongUsername) {
    const error: UseLoginError = errors.login.invalid();
    error.remainingAttempts = parseInt(remainingAttempts, 10);
    return error;
  }

  if (isApiResponseError(error) && mustUseOtc) {
    return errors.login.mustUseOtc({ message: error?.response?.body?.error?.fallbackMessage });
  }

  if (forceResetPassword) {
    return errors.login.forceResetPassword();
  }

  if (
    isApiResponseError(error) &&
    matchesError(error.response, "Too many login attempts, user is temporarily locked out of login")
  ) {
    return errors.login.lockout();
  }

  if (wrongCode) {
    return errors.login.code();
  }

  if (error instanceof DojoError) {
    return error;
  }

  throw error;
};

// This operation implements the login API call and is only for internal use.
// For app code you should be using `useLoginOperation`, so it refetches the user session
// whenever the user logs into the application.
const useInternalLoginOperation = withOTCHandling(
  makeApiMutation({
    name: "login",
    path: "/api/session",
    method: "post",
    queryParams: ["duration"] as never as "includeExtras"[], // this is awful but I don't know what else to do until the api type is updated
    onSuccess: (response, { body: params, query: { duration } }) => {
      useSessionFetcher.invalidateQueries();
      const loginType = params.login && "code" in params ? "login_code" : params.login ? "email" : "google";
      if (response.body && response.body.parent) {
        if (response.body.isNewUser) {
          logParentSignup({
            id: response.body.parent._id,
            isNewAccount: true,
            signupType: "google",
          });
        } else {
          logSignInSuccess({
            entityId: response.body.parent._id,
            accountType: "parent",
            loginType,
            duration,
          });
        }
      } else if (response && response.body.teacher) {
        logSignInSuccess({
          entityId: response.body.teacher._id,
          accountType: "teacher",
          loginType,
          duration,
        });
        return location.navigateTo(location.subdomainLink("teach"));
      } else if (response && response.body.student) {
        logSignInSuccess({
          entityId: response.body.student._id,
          accountType: "student",
          loginType,
          duration,
        });
        return location.navigateTo(location.subdomainLink("student"));
      } else {
        throw new Error("Unknown error in login");
      }
    },
    onError: (error, variables) => {
      if (!isApiResponseError(error)) {
        return;
      }

      const { failureReason, isGoogleUserNotFound, remainingAttempts } = getErrorMetadata(error);

      if (!failureReason && error.response?.body?.error) {
        logClient.logEvent({
          eventName: "web.login.failure_reason",
          metadata: { response: error.response.body.error },
        });
      }

      // don't log ERR_GOOGLE_USER_NOT_FOUND as a login.failure in order to not mess up
      // the experimentation dashboard
      if (!isGoogleUserNotFound) {
        logClient.logEvent({
          eventName: "web.login.failure",
          experiments: [],
          metadata: {
            current_site: "parent",
            login_type: "code" in variables.body ? "login_code" : "email",
            failure_reason: failureReason,
            remainingAttempts,
          },
        });
      }
    },
  }),
);

export const useGoToGoogleAuthMutation = makeApiMutation({
  name: "useGoToGoogleAuthMutation",
  method: "get",
  path: "/api/parentGoogleLoginUrl",
  queryParams: ["loginHint"],
});

function getDuration(userSpecifiedDuration: "short" | "long" | undefined) {
  if (userSpecifiedDuration) {
    return userSpecifiedDuration;
  }
  return "long";
}

export const useLoginOperation = (...args: Parameters<typeof useInternalLoginOperation>) => {
  const { isSuccess, mutate, mutateAsync, error, ...loginOperation } = useInternalLoginOperation(...args);
  const { refetch: refetchSession } = useSessionFetcher({});
  const [refetchTriggered, setRefetchTriggered] = useState(false);

  type MutateParams = Parameters<typeof mutate>;

  useWatch(!refetchTriggered && isSuccess && refetchSession, (actuallyRefetch) => {
    if (actuallyRefetch) {
      refetchSession!();
      setRefetchTriggered(true);
    }
  });

  const wrappedMutate = useCallback(
    (
      { duration: userSpecifiedDuration, email, password, code, state, autoCreate }: LoginParams,
      options?: MutateParams[1],
    ) => {
      const duration = getDuration(userSpecifiedDuration);
      return mutate(
        {
          query: duration ? { duration } : {},
          body: (email
            ? { login: email, password, code }
            : { googleToken: code, state, autoCreate }) as MutateParams[0]["body"],
        },
        options,
      );
    },
    [mutate],
  );

  //         body: email ? { login: email, password, code } : { googleToken: code, state, autoCreate },

  const wrappedMutateAsync = useCallback(
    (
      { duration: userSpecifiedDuration, email, password, code, state, autoCreate }: LoginParams,
      options?: MutateParams[1],
    ) => {
      const duration = getDuration(userSpecifiedDuration);
      return mutateAsync(
        {
          query: duration ? { duration } : {},
          body: (email
            ? { login: email, password, code }
            : { googleToken: code, state, autoCreate }) as MutateParams[0]["body"],
        },
        options,
      );
    },
    [mutateAsync],
  );

  return {
    ...loginOperation,
    error: mapUnknownLoginErrorToDojoError(error),
    isSuccess,
    mutate: wrappedMutate,
    mutateAsync: wrappedMutateAsync,
  };
};

// [TSM] TODO: Use API helpers when they are moved to shared folder
export const useSessionFetcher = makeMemberQuery({
  fetcherName: "sessionFetcher",
  path: "/api/session",
  query: {
    includeExtras: "location",
    supportsChildAsParent: "true",
  },
  dontThrowOnStatusCodes: [401],
});

/**
 * Parents have a new way to login in order to access dojoIslands.
 * We need to differentiate between the standard way and the new way.
 *
 * The "new way" being the dojoIslands one-time-code, @see https://dojoislands.com/
 *
 * This check returns `true` if the current session is the standard parent session,
 * that has full access to all the endpoints.
 */
export function useIsStandardParentSession() {
  const { data: session } = useSessionFetcher({});

  return session && session.parent && !session.isChildAsParent;
}

const _useModifySession = makeApiMutation({
  name: "modifySession",
  path: "/api/session",
  method: "patch",
});

export const useModifySession = () => {
  const { mutate: modifySession } = _useModifySession({});
  return {
    makeSessionReadOnly: useCallback(() => modifySession({ body: { readOnly: true } }), [modifySession]),
    makeSessionWritable: useCallback(() => modifySession({ body: { readOnly: false } }), [modifySession]),
  };
};

export type SessionFetcherResponse = MemberFetcherReturnType<typeof useSessionFetcher>;
