import { createSaveMessageDraftSaga } from "@classdojo/web/pods/messaging/index";
import { UploadedFileInfo } from "@classdojo/web/utils/uppy";
import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import createAction from "@web-monorepo/infra/createAction";
import { APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { useParentUnreadsFetcher } from "@web-monorepo/shared/messaging/hooks";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import { NOOP } from "@web-monorepo/shared/reactQuery";
import set from "lodash/set";
import orderBy from "lodash/orderBy";
import type { AnyAction } from "redux";
import { useSessionFetcher } from "app/pods/session";
import { localStorage } from "@web-monorepo/safe-browser-storage";
import { PARENT_FEATURE_SWITCHES } from "app/pods/featureSwitches/constants";
import { useFetchedIsFeatureSwitchOn } from "@web-monorepo/shared/featureSwitches";

const STATE_KEY = "messaging";

type MessagingState = {
  pendingMessages: Record<string, Message>;
  drafts: Record<string, Message>;
};

export type MessagingSlice = {
  [STATE_KEY]: MessagingState;
};

const initialState: MessagingState = {
  // All of the following are by channel or class (for broadcast channel)
  pendingMessages: {},
  drafts: {},
};

// Includes Mesage entity and extra state properties added by client-side updates.
export type Message = APIResponse<"/api/message", "get">["_items"][number] & {
  translation?: string;
  translationDisplayed?: boolean;
  translationRequested?: boolean;
  translationFailed?: boolean;
  channelId?: string;
  classId?: string;
  failed?: boolean;
  tempId?: string;
  pending?: boolean;
  readAt?: Date | string;
};

export const messagesOrderBy = (messages?: Message[]): Message[] => orderBy<Message>(messages, "createdAt", "asc");

const SAVE_MESSAGE_DRAFT = createAction("messaging/save_message_draft");

export const saveDraft = (targetId: string, body: string, attachments: UploadedFileInfo[]) => ({
  type: SAVE_MESSAGE_DRAFT,
  payload: { targetId, body, attachments },
});

type SaveDraftAction = {
  type: typeof SAVE_MESSAGE_DRAFT;
  payload: {
    targetId: string;
    body: string;
    attachments: UploadedFileInfo[];
  };
};

const isSaveDraftAction = (action: AnyAction): action is SaveDraftAction => {
  return action.type === SAVE_MESSAGE_DRAFT;
};

const saveDraftHandler = (state: MessagingState, action: AnyAction) => {
  if (isSaveDraftAction(action)) {
    const { targetId, body, attachments } = action.payload;
    return set(state, `drafts.${targetId}`, { body, attachments });
  }

  return state;
};

export const selectDraftForChannel = (state: MessagingSlice, channelId: string) => {
  if (state[STATE_KEY].drafts[channelId] && state[STATE_KEY].drafts[channelId]?.body != "") {
    return state[STATE_KEY].drafts[channelId];
  } else {
    const draft = { body: localStorage.getItem(`message_draft.${channelId}`) };
    return draft;
  }
};

const reducer = combineActionHandlers(initialState, [saveDraftHandler]);

const install: PodInstallFunction = (installReducer, installSaga) => {
  installReducer(STATE_KEY, reducer);
  installSaga(createSaveMessageDraftSaga(SAVE_MESSAGE_DRAFT, localStorage));
};

export default install;

export const useTotalUnreadThreadMessageCount = () => {
  const { data: session } = useSessionFetcher({});
  const includeBulletinsUnreadCount = useFetchedIsFeatureSwitchOn(PARENT_FEATURE_SWITCHES.WEB_VIEW_BULLETINS);
  const { data: unreads } = useParentUnreadsFetcher(
    session && session.parent && !session.isChildAsParent
      ? {
          parentId: session?.parent._id,
          ...(includeBulletinsUnreadCount ? { includeBulletins: "true" } : {}),
        }
      : NOOP,
  );
  return unreads?.messagesCount ?? 0;
};
