import { logEvent } from "@classdojo/log-client";
import PubnubClient from "@classdojo/web/pods/realtime/adapters/pubnub";
import { Message } from "@classdojo/web/pods/realtime/adapters/types";
import { metrics } from "app/utils/metrics";
import * as Pubnub from "app/pods/pubnub";
import { getStore } from "app/store";
import env from "app/utils/env";

/* eslint-disable @typescript-eslint/no-namespace, no-var */
declare global {
  namespace NodeJS {
    interface Global {
      appPubnub: typeof PubnubUtil;
    }
  }

  namespace globalThis {
    var appPubnub: typeof PubnubUtil;
  }
}
/* eslint-enable @typescript-eslint/no-namespace, no-var */

/**
 * This module holds a singleton Pubnub client.
 */

type PubnubClientMock = {
  subscribe: () => void;
  unsubscribe: () => void;
  publish: () => void;
};

const PUBNUB_CLIENT_ID_COOKIE = "dojo_pubnub_clientId";

const pubnubKeyCookie = document.cookie
  .split("; ")
  .find((cookie) => cookie.startsWith(`${PUBNUB_CLIENT_ID_COOKIE}`))
  ?.split("=")[1];

let clientId = pubnubKeyCookie;

if (env.isTesting) {
  clientId = "dojo-testingEnv";
}

if (!clientId) {
  const tempArray = new Uint32Array(10);
  const crypto = window.crypto || window.msCrypto;
  const randomNumbers = pubnubKeyCookie ?? crypto.getRandomValues(tempArray)[0];
  clientId = `dojo-${randomNumbers}`;
  document.cookie = `${PUBNUB_CLIENT_ID_COOKIE}=${clientId}`;
}

let client: PubnubClient | PubnubClientMock = {
  subscribe() {},
  unsubscribe() {},
  publish() {},
};

function log(...args: unknown[]) {
  if (env.isDev && !global.hideLogs) {
    // eslint-disable-next-line no-console
    console.log("PUBNUB - ", ...args);
  }
}

const PubnubUtil = {
  client,
  permanentChannels: [] as string[],
  /**
   * @param  {String} entityId
   * @return {String}
   */
  init: function init(entityId: string) {
    if (!env.isTesting) {
      client = new PubnubClient({ metrics, logEvent, userId: entityId });
    }
    this.permanentChannels = [entityId];
    client.subscribe(this.permanentChannels, this.onMessage);
  },

  publish(message: Message, channel: string) {
    log("On channel", channel, "publishing message:", message);

    client.publish(channel, {
      ...message,
      source_id: clientId,
    });
  },

  /**
   * @param {String} channel
   */
  subscribe: function subscribe(channel: string | string[], timetoken: number) {
    log("Subscribing to channels", channel);

    client.subscribe(channel, this.onMessage, timetoken);
  },

  /**
   * @param {String} channel
   */
  unsubscribe: function unsubscribe(channel: string) {
    // Never unsubcribe from the init subscriptions
    if (!this.permanentChannels.includes(channel)) {
      log("Unsubscribed from channel:", channel);
      client.unsubscribe(channel, this.onMessage);
    }
  },

  /**
   * @param {Object} message
   */
  onMessage: (message: Message) => {
    // let's avoid echo chamber

    if (message.source_id === clientId) {
      return;
    }

    const command = message.command || message.action;

    getStore().dispatch(Pubnub.receiveMessage(command || "", message || ""));
  },
};

global.appPubnub = PubnubUtil;

export default PubnubUtil;
