import callApi, { CallApiDefaultResponse } from "@web-monorepo/infra/callApi";
import {
  APIRequestParameters,
  CollectionFetcherReturnType,
  MemberFetcherReturnType,
} from "@web-monorepo/shared/api/apiTypesHelper";
import { makeCollectionQuery, makeMemberQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import keyBy from "lodash/keyBy";
import flatMap from "lodash/flatMap";
import find from "lodash/find";
import env from "app/utils/env";
import * as location from "app/utils/location";

const PORT = Config.port ?? "8089";

const studentsByStudentClassKey = (studentId: string, classId: string) => `${studentId}-${classId}`;

export type StudentClass = Student["classes"][number];

export type StudentClone = {
  class: StudentClass;
  teacher: StudentClass["teacher"];
  avatar: StudentClass["avatar"];
  classes?: StudentClass[];
} & Omit<Student, "classes" | "avatar">;

const keyFn = (item: StudentClone) => studentsByStudentClassKey(item._id, item.class._id);

/**
 * This method will filter the classes within a student obj to return those which are not archived
 * The output will become an obj with repeated student ids as keys and individual classes per student
 * { [studentId]: { studentObj, class: { classObj } } }
 */
export function createStudentsByClass(students?: Student[]): Record<string, StudentClone> {
  return keyBy(
    flatMap(students, (student) => {
      return student.classes
        .filter((c) => !c.archived)
        .map((classroom) => {
          const clone: StudentClone = Object.assign(
            { class: classroom, teacher: classroom.teacher, avatar: classroom.avatar },
            student,
          );
          delete clone.classes;
          return clone;
        });
    }),
    keyFn,
  );
}

type GenerateTempStudentPasswordOperationParams = APIRequestParameters<
  "/api/studentUser/{studentUserId}/tempPassword",
  "post"
>["path"];
export const useGenerateTempStudentPasswordOperation = makeMutation<
  GenerateTempStudentPasswordOperationParams,
  CallApiDefaultResponse
>({
  name: "tempStudentPassword",
  fn: async ({ studentUserId }) => {
    return await callApi({
      method: "POST",
      path: `/api/studentUser/${studentUserId}/tempPassword`,
    });
  },
  onSuccess: (data, params) => {
    useStudentsFetcher.setQueriesData((cacheData) => {
      const student: (Student & { studentUser?: { tempPassword?: string } }) | undefined = find(cacheData, {
        studentUser: { _id: params.studentUserId },
      });
      if (student?.studentUser) {
        student.studentUser.tempPassword = data.body.password;
      }
    });
  },
});

export type KidLoginOperationRequestBody = {
  studentId: string;
  token: string;
  route?: string;
} & { [key: string]: unknown };
export const useKidLoginOperation = makeMutation<KidLoginOperationRequestBody, CallApiDefaultResponse | void>({
  name: "studentLogin",
  fn: async ({ studentId, token, route }) => {
    await callApi({
      path: `/api/session`,
      method: "POST",
      body: { studentId, token },
    });

    const hashLocation = route || "/classroomCheck";

    const url = env.isProd
      ? `https://student.classdojo.com/#${hashLocation}`
      : `https://student.classdojo.test:${PORT}/#${hashLocation}`;

    location.navigateTo(url);
  },
});

export type Student = CollectionFetcherReturnType<typeof useStudentsFetcher>;

export const useStudentsFetcher = makeCollectionQuery({
  fetcherName: "students",
  path: `/api/parent/{parentId}/student`,
});

export const usePStudentByTokenFetcher = makeMemberQuery({
  fetcherName: "pstudent",
  path: "/api/parentActivationToken/{token}",
  dontThrowOnStatusCodes: [404],
});
export type PStudentByTokenResponse = MemberFetcherReturnType<typeof usePStudentByTokenFetcher>;
